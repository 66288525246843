.connector-container {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
  padding: 10px;
  display: flex;
  height: 72px;
  background-color: white;
  margin: 0 16px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.connector-status-live {
  background-color: rgba(40, 199, 126, 1);
  width: 6px;
  height: 6px;
  border-radius: 8px;
}

.connector-status-dead {
  background-color: rgba(234, 66, 66, 1);
  width: 6px;
  height: 6px;
  border-radius: 8px;
}

.connector-detail {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.connector-detail-1 {
  padding-left: 8px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
}

.connector-detail-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
