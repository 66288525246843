.profile-edit-detail-container {
  display: flex;
  flex-direction: row;
  background-color: white;
  margin: 8px 16px;
  padding: 8px 16px;
  border-radius: 8px;
}

.profile-edit-detail-input {
  border-width: 0;
  font-family: Proxima Nova;
  padding: 8px 0;
  width: 100%;
}
