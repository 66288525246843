.profile-body {
  background-color: #f5f5f5;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.profile-picture-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px 0;
}

.profile-picture {
  width: 96px;
  height: 96px;
  border-radius: 50%;
}

.profile-detail-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 16px;
  border: 1px solid rgba(152, 161, 169, 0.12);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background-color: white;
  padding: 16px;
}

.profile-detail-text-container {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
}

.profile-edit-button-container {
  display: flex;
  align-items: flex-end;
  flex: 1;
  margin: 16px;
}
