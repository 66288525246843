.container {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  flex-direction: row;
  height: 52px;
  background-color: white;
  padding: 18px;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 30px;
  width: 30px;
}

.bell-logo {
  height: 20px;
  width: 20px;
  margin: 0 8px;
}

.favorite-logo {
  height: 17px;
  width: 17px;
  margin: 0 8px;
}

.menu-logo {
  height: 22px;
  width: 22px;
  margin: 0 8px;
}

.navigation-details {
  display: flex;
  flex-direction: row;
  padding: 8px 16px !important;
  align-items: flex-start;
  background-color: white;
}

.navigation-notes {
  display: flex;
  flex-direction: row;
  padding: 0px 18px;
  align-items: flex-start;
  background-color: white;
  margin-top: -8px;
  padding-left: 38px;
  max-height: 56px;
  overflow: hidden;
  /* border-bottom: 1px solid #adb5bd; */
}

.location-logo {
  margin-top: 5px;
  margin-right: 6px;
}

.location {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.navigation-icon {
  height: 10px;
  width: 10px;
  margin-top: 3px;
  margin-right: 6px;
  margin-left: 2px;
}

.page-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 9px;
  padding-top: 9px;
  background-color: white;
  align-items: center;
}

.page-url {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.copy-icon {
  opacity: 0.36;
  cursor: pointer;
  margin-right: 16px;
}

.body {
  display: flex;
  flex-direction: column;
}

.body-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
}

.action-button-container {
  padding: 16px;
  background-color: white;
  margin-bottom: 24px;
}

.action-button-text {
  padding: 12px 4px;
  font-family: 'Proxima Nova' !important;
  color: rgb(152, 161, 169);
  font-size: 12px;
}

.connector-additional-detail-container {
  padding: 18px;
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
}

.tariff-container {
  display: flex;
  flex-direction: row;
  padding: 7px 0;
  line-height: 20px;
}

.currency-icon {
  height: 22px;
  padding-left: 6px;
  padding-right: 12px;
}

.availability-container {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}

.available-icon {
  height: 23px;
  padding-right: 12px;
}

.information-icon {
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 12px;
  color: #98a1a9;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Toastify__toast {
  background-color: #34414a;
  margin: 16px 48px;
  border-radius: 32px;
  max-height: 56px;
  min-height: 32px;
  text-align: center;
}

.Toastify__toast-body {
  font-family: 'Proxima Nova';
  font-size: 14px;
  font-weight: 400;
  padding: 0 8px;
}
