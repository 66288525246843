.session-body {
  background-color: white;
  min-height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.session-information {
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  font-family: 'Proxima Nova' !important;
  background-color: rgba(52, 65, 74, 0.06);
  font-size: 12px;
  overflow: hidden;
}
