.parking-container {
  display: flex;
  flex-direction: row;
  padding: 7px 0;
  align-items: center;
  line-height: 20px;
}

.information-icon {
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 12px;
  color: #98a1a9;
}
