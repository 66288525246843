.navigation-link-container {
  padding: 8px;
  cursor: pointer;
  text-align: center;
  width: 100%;
}

.navigation-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 16px;
}

.navigation-bar-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  /* cursor: pointer; */
}

.navigation-expand-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 56px;
  width: 100%;
  background-color: #f1f1f1;
  z-index: 1000;
}
