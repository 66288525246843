.pac-container {
  /* width: 100% !important; */
  margin-left: -38px;
  margin-top: 10px;
  width: 96% !important;
  max-width: 500px !important;
}

.pac-item {
  padding: 4px 8px;
  font-family: 'Proxima Nova';
}

.navigation-details {
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  align-items: flex-start;
  background-color: white;
  margin-top: 8px;
}

.location-logo {
  margin-top: 5px;
  margin-right: 6px;
}

.location {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.page-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 9px;
  padding-top: 9px;
  background-color: white;
  align-items: center;
}

.page-url {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.copy-icon {
  opacity: 0.36;
  cursor: pointer;
}

.navigation-icon {
  height: 10px;
  width: 10px;
  margin-top: 3px;
  margin-right: 6px;
  margin-left: 2px;
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}
